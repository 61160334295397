import ApiRoutes from 'src/constants/ApiRoutes';
import { ApiError } from 'src/types/Api';
import { useQuery } from '@tanstack/react-query';
import { UserResponse } from 'src/types/User';
import { getUserUuid, getHeaders } from '@get-mosh/msh-shared';

async function getUser() {
    const userUuid = getUserUuid();

    if (!userUuid) {
        return null;
    }

    const res = await fetch(ApiRoutes.USER(userUuid), {
        method: 'GET',
        headers: getHeaders(),
    });
    const data = await res.json();

    if (!res.ok) {
        throw data as ApiError;
    }

    return data as UserResponse;
}

export const useUser = () => {
    return useQuery<UserResponse | null, ApiError>({
        queryKey: ['User'],
        queryFn: () => getUser(),
    });
};
